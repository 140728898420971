// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-via-jsx-js": () => import("./../../../src/pages/about_via_jsx.js" /* webpackChunkName: "component---src-pages-about-via-jsx-js" */),
  "component---src-pages-contact-via-mdx-mdx": () => import("./../../../src/pages/contact_via_mdx.mdx" /* webpackChunkName: "component---src-pages-contact-via-mdx-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-by-graph-cms-js": () => import("./../../../src/templates/page_by_graphCMS.js" /* webpackChunkName: "component---src-templates-page-by-graph-cms-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

